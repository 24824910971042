<template>
  <div>
    <header>
      <HeaderNavHomepage />
      <main>
        <div class="main-page-info">
          <img src="/img/estimate-header.svg" alt="" />
          <p>
            Estimate your potential earnings <br />
            from your event.
          </p>
        </div>
        <img src="/img/blur-circle.svg" class="blur1" alt="" />
        <img src="/img/blur-circle.svg" class="blur2" alt="" />
        <img src="/img/blur-circle.svg" class="blur3" alt="" />
      </main>
    </header>

    <div class="hero">
      <Pricing />
      <LaunchSubscriptionsCalc />
      <MainFooter />
    </div>
  </div>
</template>

<script>
import HeaderNavHomepage from '@/components/HeaderNavHomepage.vue';
import LaunchSubscriptionsCalc from '@/components/LaunchSubscriptionsCalc.vue';
import MainFooter from '@/components/MainFooter.vue';
import Pricing from '@/components/Pricing.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';

export default {
  name: 'IncomeCalculator',
  components: {
    HeaderNavHomepage,
    LaunchSubscriptionsCalc,
    Pricing,
    MainFooter,
  },

  setup() {
    useHead(
      buildPageMeta({
        title: 'Income Calculator | Estimate your earnings from HelixPay',
        description:
          'Content Creators use HelixPay Ecommerce Technology to generate hundreds of thousands of pesos in earnings from their fans and community.',
        url: 'https://www.helixpay.ph/incomecalculator',
        image: 'https://www.helixpay.ph/img/helixpay_ticketing.jpg',
      })
    );
  },
};
</script>

<style scoped>
header {
  background: linear-gradient(-90deg, #25a4e1, #a5e2ff, #a5e2ff);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: inherit;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

main {
  width: 100%;
  height: inherit;
  position: relative;
  overflow: hidden;
  background-color: black;
}

.main-page-info {
  display: grid;
  gap: 3rem;
  text-align: center;
  max-width: 60rem;
  padding: 5rem 2rem;
  margin: auto;
  z-index: 9;
}

.main-page-info img {
  z-index: 9;
  pointer-events: none;
}

.main-page-info p {
  z-index: 999;
  margin: auto;
  color: white;
  font-weight: 600;
  font-size: 1.5rem;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ MAIN CIRCLE */

.blur1 {
  width: 45%;
  right: -15%;
  bottom: -30%;
  z-index: 8;
  position: absolute;
  pointer-events: none;
  animation: move 200s ease-out infinite;
}

@keyframes move {
  0% {
    width: 45%;
    right: -20%;
  }
  100% {
    width: 50%;
    right: 100%;
    bottom: 40%;
  }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Blur 2 */

.blur2 {
  opacity: 0.5;
  width: 15%;
  left: 1%;
  bottom: -30%;
  z-index: 7;
  position: absolute;
  pointer-events: none;
  animation: right 140s ease-out infinite;
}

@keyframes right {
  0% {
    width: 15%;
    left: -15%;
    bottom: -10%;
  }
  100% {
    width: 25%;
    left: 100%;
    bottom: 20%;
  }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Blur 3 */

.blur3 {
  opacity: 0.5;
  width: 5%;
  left: 1%;
  top: -10%;
  z-index: 6;
  position: absolute;
  pointer-events: none;
  animation: lower 150s ease-out infinite;
}

@keyframes lower {
  0% {
    width: 5%;
    left: -15%;
    top: -10%;
  }
  100% {
    width: 10%;
    left: 100%;
    top: 50%;
  }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.hero {
  background-color: white;
}

@media only screen and (max-width: 1200px) {
  .main-page-info img,
  .main-page-info p {
    z-index: 2;
  }

  .blur1,
  .blur2,
  .blur3 {
    z-index: 1;
  }

  .blur1 {
    animation: move 30s ease-out infinite;
  }

  @keyframes move {
    0% {
      width: 55%;
      right: -20%;
    }
    100% {
      width: 55%;
      right: 100%;
      bottom: 40%;
    }
  }

  .blur2 {
    animation: right 20s ease-out infinite;
  }

  @keyframes right {
    0% {
      opacity: 0;
      width: 45%;
      left: -15%;
      bottom: -10%;
    }
    100% {
      opacity: 1;
      width: 45%;
      left: 100%;
      bottom: 20%;
    }
  }

  .blur3 {
    animation: lower 20s ease-out infinite;
  }

  @keyframes lower {
    0% {
      width: 25%;
      left: -15%;
      top: -10%;
    }
    100% {
      width: 25%;
      left: 100%;
      top: 70%;
    }
  }
}

@media only screen and (max-width: 650px) {
  .main-page-info p {
    font-size: 1rem;
  }
}
</style>
